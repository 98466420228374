import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 92 100" fill="none" {...props}>
          <g opacity="0.6">
              <rect x="11.4824" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="11.4824" y="18.627" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="11.4824" y="37.7441" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="11.4824" y="56.373" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="11.4824" y="75.4902" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="11.4824" y="94.1172" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="29.9634" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="29.9634" y="18.627" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="29.9634" y="37.7441" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="29.9634" y="56.373" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="29.9634" y="75.4902" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="29.9634" y="94.1172" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="48.4453" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="48.4453" y="18.627" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="48.4453" y="37.7441" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="48.4453" y="56.373" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="48.4453" y="75.4902" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="48.4453" y="94.1172" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="66.9272" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="66.9272" y="18.627" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="66.9272" y="37.7441" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="66.9272" y="56.373" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="66.9272" y="75.4902" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="66.9272" y="94.1172" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="85.4087" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="85.4087" y="18.627" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="85.4087" y="37.7441" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="85.4087" y="56.373" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="85.4087" y="75.4902" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
              <rect x="85.4087" y="94.1172" width="5.83635" height="5.88233" rx="2.91818" fill="#13486B"/>
          </g>
    </Svg>
  );
};

export default Icon;
