import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 27 140" fill="none" {...props}>
        <g clipPath="url(#clip0_5_19)">
        <rect x="1" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="1" y="6.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="1" y="13.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="1" y="19.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="1" y="26.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="1" y="32.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.90088" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.90088" y="6.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.90088" y="13.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.90088" y="19.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.90088" y="26.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.90088" y="32.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.8022" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.8022" y="6.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.8022" y="13.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.8022" y="19.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.8022" y="26.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.8022" y="32.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.7028" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.7029" y="6.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.7029" y="13.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.7029" y="19.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.7029" y="26.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.7029" y="32.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="23" y="40" width="8" height="100" rx="4" fill="#F9A138"/>
        </g>
        <defs>
        <clipPath id="clip0_5_19">
        <rect width="27" height="140" fill="white"/>
        </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;
