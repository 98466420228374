import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CsSvg = styled(Svg)`
  width: 16px;
  height: 16px;
  @media screen and (max-width: 768px){
    width: 12px;
    height: 12px;
  }
`

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <CsSvg width="16" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.600466 8.00014C0.600466 7.35847 0.847964 6.71681 1.3338 6.23097L7.31047 0.254307C7.5763 -0.0115267 8.0163 -0.0115267 8.28213 0.254307C8.54796 0.52014 8.54796 0.96014 8.28213 1.22597L2.30546 7.20264C1.86546 7.64264 1.86546 8.35764 2.30546 8.79764L8.28213 14.7743C8.54796 15.0401 8.54796 15.4801 8.28213 15.746C8.0163 16.0118 7.5763 16.0118 7.31047 15.746L1.3338 9.76931C0.847964 9.28347 0.600466 8.64181 0.600466 8.00014Z" fill="#02111B"/>
    </CsSvg>
  );
};

export default Icon;
