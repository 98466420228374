import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
        <path d="M6 5.11524V12.8852C6 13.4777 6.6525 13.8377 7.155 13.5152L13.26 9.63024C13.3669 9.5626 13.4549 9.46902 13.5159 9.35822C13.5769 9.24741 13.6089 9.12298 13.6089 8.99649C13.6089 8.87 13.5769 8.74556 13.5159 8.63476C13.4549 8.52396 13.3669 8.43038 13.26 8.36274L7.155 4.48524C7.04188 4.41207 6.9111 4.37079 6.77647 4.36576C6.64184 4.36072 6.50835 4.39212 6.39008 4.45663C6.27181 4.52114 6.17314 4.61638 6.10448 4.7323C6.03583 4.84822 5.99973 4.98051 6 5.11524Z"/>
    </Svg>
  );
};

export default Icon;
