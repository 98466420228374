import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 43 59" fill="none" {...props}>
        <g clipPath="url(#clip0_4_531)">
        <rect x="5" y="21" width="38" height="38" rx="8" fill="#F9A138"/>
        <rect x="0.900879" y="1" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="0.900879" y="7.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="0.900879" y="14.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="0.900879" y="20.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="0.900879" y="27.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="0.900879" y="33.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.80225" y="1" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.80225" y="7.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.80225" y="14.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.80225" y="20.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.80225" y="27.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="8.80225" y="33.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.7028" y="1" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.7029" y="7.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.7029" y="14.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.7029" y="20.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.7029" y="27.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="16.7029" y="33.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.6041" y="1" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.6042" y="7.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.6042" y="14.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.6042" y="20.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.6042" y="27.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="24.6042" y="33.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="32.505" y="1" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="32.5051" y="7.49518" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="32.5051" y="14.161" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="32.5051" y="20.6558" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="32.5051" y="27.322" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        <rect x="32.5051" y="33.8168" width="2.49505" height="2.05105" rx="1.02553" fill="#F9A138"/>
        </g>
        <defs>
        <clipPath id="clip0_4_531">
        <rect width="43" height="59" fill="white"/>
        </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;
