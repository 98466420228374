import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 75 75" fill="none" {...props}>
        <rect width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect y="13.9707" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect y="28.3086" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect y="42.2793" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect y="56.6172" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect y="70.5879" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="14.1089" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="14.1089" y="13.9707" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="14.1089" y="28.3086" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="14.1089" y="42.2793" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="14.1089" y="56.6172" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="14.1089" y="70.5879" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="28.2183" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="28.2183" y="13.9707" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="28.2183" y="28.3086" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="28.2183" y="42.2793" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="28.2183" y="56.6172" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="28.2183" y="70.5879" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="42.3267" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="42.3267" y="13.9707" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="42.3267" y="28.3086" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="42.3267" y="42.2793" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="42.3267" y="56.6172" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="42.3267" y="70.5879" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="56.4355" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="56.4355" y="13.9707" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="56.4355" y="28.3086" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="56.4355" y="42.2793" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="56.4355" y="56.6172" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="56.4355" y="70.5879" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="70.5444" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="70.5444" y="13.9707" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="70.5444" y="28.3086" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="70.5444" y="42.2793" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="70.5444" y="56.6172" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>
        <rect x="70.5444" y="70.5879" width="4.45545" height="4.41177" rx="2.20588" fill="#F9A138"/>

    </Svg>
  );
};

export default Icon;
